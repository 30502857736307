import axios from 'axios';
import config from 'config'

export const getAuth = function() {
    const userToken = sessionStorage.getItem('access_token');
    return userToken && userToken
}
export const setAuth = function(token) {
    sessionStorage.setItem('access_token', token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
export const removeAuth = function() {
    sessionStorage.removeItem('access_token')
}

const onSuccess = (response) => {
    if( response && response.data && response.data['access_token']) {
        setAuth(response.data['access_token'])
        axios.defaults.headers.common['Authorization'] = "Bearer " + response.data['access_token'];
    }
    if( response.status === 401 ) window.location.href = '/login'
    return response;
}

axios.defaults.baseURL = config.ServerURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = "Bearer " + getAuth();
axios.interceptors.response.use(onSuccess)

export const login = function(query) {
    return axios({
        method: 'POST',
        url: 'login',
        data: JSON.stringify(query)
    })
}

export const register = function(query) {
    return axios({
        method: 'POST',
        url: 'signup',
        data: JSON.stringify(query)
    })
}

export const logout = function() {
    axios.defaults.headers.common['Authorization'] = "Bearer " + getAuth();
    return axios({
        method: 'POST',
        url: 'logout',
    })
}

export const verifyEmail = function() {
    return axios({
        method: 'POST',
        url: 'verify_email',
    })
}

export const changePassword = function(data) {
    return axios({
        method: 'POST',
        url: 'change_password',
        data: JSON.stringify(data)
    })
}

export const updateUserProfile = function(data) {
    return axios({
        method: 'POST',
        url: 'update_user_info',
        data: JSON.stringify(data)
    })
}

export const deleteAccount = function() {
    return axios({
        method: 'POST',
        url: 'delete_account'
    })
}

export const processYoutube = function(data) {
    return axios({
        method: 'POST',
        url: 'process/youtube',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}

export const processVideo = function(data) {
    return axios({
        method: 'POST',
        url: 'process/video',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}

export const getProcesses = function() {
    return axios({
        method: "POST",
        url: 'get_processes'
    })
}

export const processStandard = function(data) {
    return axios({
        method: 'POST',
        url: 'process/standard',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}

export const getStandards = function() {
    return axios({
        method: "POST",
        url: 'get_standards'
    })
}

export const getUserInfo = function(query) {
    return axios({
        method: 'POST',
        url: 'get_user_info',
        data: JSON.stringify(query)
    })
}

export const generateAPIKEY = function(query) {
    return axios({
        method: 'POST',
        url: 'generate_api_key',
        data: JSON.stringify(query)
    })
}
export const getAPIKeys = function() {
    return axios({
        method: 'POST',
        url: 'get_api_keys',
    })
}

export const getResult = function(query) {
    return axios({
        method: 'POST',
        url: 'search',
        data: JSON.stringify(query)
    })
}

export const getComments = function(query) {
    return axios({
        method: 'POST',
        url: 'comments',
        data: JSON.stringify(query)
    })
}

export const getRelated = function(query) {
    return axios({
        method: 'POST',
        url: 'related',
        data: JSON.stringify(query)
    })
}

export const addComment = function(query) {
    return axios({
        method: 'POST',
        url: 'addcomment',
        data: JSON.stringify(query)
    })
}

export const chatWithAI = function(query) {
    return axios({
        method: 'POST',
        url: 'chatbot',
        data: JSON.stringify(query)
    })
}

export const createChatSession = function() {
    return axios({
        method: 'POST',
        url: 'createChatSession',
    })
}