import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CookiesModal from 'components/CookieModal';
import './_home.scss';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

export default function Home() {
    const navigate = useNavigate();
    const [ isCookieAccepted, setIsCookieAccepted ] = useState(false);

    const cookieStatus = useMemo(() => {
        return Cookies.get('privacyPolicy');
    }, []);
  
    useEffect(() => {
        if(cookieStatus === 'accepted') {
            setIsCookieAccepted(true)
        } else {
            setIsCookieAccepted(false)
        }
    }, [cookieStatus])
  
    function cookieAccept() {
        console.log('Cookies are accepted.')
        setIsCookieAccepted(true)
        Cookies.set('privacyPolicy', 'accepted');
    }

    return (
        <div className="home_page">
            <Logo />
            <div className='button_container'>
                <button onClick={() => navigate('/search')}>
                    Search
                </button>
                <button onClick={() => navigate('/user/media')}>
                    Upload
                </button>
            </div>
            {
                !isCookieAccepted &&
                <CookiesModal cookieAccept={cookieAccept} />
            }
        </div>
    );
}
  