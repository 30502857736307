import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import Loading from 'components/Loading';
import Store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/_main.scss';

import Home from 'pages/Home';
import Login from 'pages/Login';
import Signup from 'pages/Signup';
import NotFound from 'pages/NotFound';

const App = React.lazy(() => import('./app'));

const app = (
  <React.StrictMode>
    <Provider store={Store}>
      <Router >
        <Routes>
          <Route path='/' element={ <Home />} />
          <Route path='/*' element={ 
            <Suspense fallback={<Loading />}>
              <App />
            </Suspense>
          } />
          <Route path='/signup' element={ <Signup /> } />
          <Route path="/login" element={ <Login /> } />
          <Route path="*" element={ <NotFound /> } />
        </Routes>
      </Router>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));